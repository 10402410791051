/**
 * @module Home
 * @description
 * A router-managed component that shows something.
 */

import React from 'react';

import { Link } from "react-router-dom";

export default function Home() {

  return (
    <div>
      <div className="jumbotron mb-4">
        <p className="lead">Welcome to AmbieSense</p>
        <h3 className="fw-light text-end">&ldquo;We haz cameras&rdquo;</h3>
      </div>
      <h3>
        <Link to="/streams">Current streams</Link>
      </h3>
    </div>
  );
}
