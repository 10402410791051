/**
 * @module Streams
 * @description
 * A router-managed component that shows something.
 */

import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

function getStreams() {
  return fetch('/api/streams', {
    headers: {
      'Authorization': 'Basic YWRtaW46dGVycmFieXRlcw=='
    }
  })
    .then((response) => response.json());
}

export default function Streams() {

  const [streams, setStreams] = useState(null);

  useEffect(() => {
    let mounted = true;
    getStreams()
      .then((data) => {
        if(mounted) {
          setStreams(data)
        }
      });
    return () => mounted = false;
  }, []);

  const liveStreams = streams ? streams.live : {};

  return (
    <div>
      <h2 className="ambie-breadcrumb">
        <div className="ambie-breadcrumb-item active" aria-current="page">
          All streams
        </div>
      </h2>

      {
        Object.keys(liveStreams || {}).map((k) => 
          <p key={k}>
            <Link to={ "/streams/" + encodeURIComponent(k) } >
              { k }
            </Link>
          </p>
        )
      }
    </div>
  );
}
