/**
 * @module StreamSettings
 * @description
 * A router-managed component that shows something.
 */

import React, { useState, useEffect, useRef } from 'react';

function sendMessage(id, data) {
  const message = Object.assign({}, data, {stream: id});
  return fetch('/api/messages', {
    headers: {
      'Authorization': 'Basic YWRtaW46dGVycmFieXRlcw==',
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(message),
    method: "post"
  });
}


export default function StreamSettings({id}) {

  const formElement = useRef(null);

  const [splitScreen, setSplitScreen] = useState(false);
  const [enhanceLevel, setEnhanceLevel] = useState(4.0);

  useEffect(() => {
    const state = { splitScreen, enhanceLevel };
    sendMessage(id, state);
  }, [splitScreen, enhanceLevel]) 

  return (
    <form ref={ formElement } className="form-inline mt-3" name='streamSettingsForm'>

      <label htmlFor="inputEnhanceLevel">Enhancement: { enhanceLevel }</label>
      <input type="range" 
             min={ 0 } max={ 40 } step={ 1 }
             className="form-control-range" 
             value={ enhanceLevel }
             onInput={e => setEnhanceLevel(parseFloat(e.target.value))}
             id="inputEnhanceLevel" />

      <div className="form-check form-check-inline">
        <input className="form-check-input" 
               type="checkbox" 
               id="inputSplitScreen" 
               value={ splitScreen }
               onChange={e => setSplitScreen(! splitScreen)}
        ></input>
        <label className="form-check-label" htmlFor="inputSplitScreen">Split screen</label>
      </div>

    </form>
  );

}