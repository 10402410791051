/**
 * @module Container
 * @description
 * Provides the main navigation system for the web application. The
 * application is using Bootstrap, so most of what is happening here
 * is a Bootstrap navigation bar, with a little redux magic to pull
 * an authenticated user out into menu state.
 */

import React from "react";
import {
  BrowserRouter as Router
} from "react-router-dom";

import ApplicationRouter from './Router';
import Navigation from './Navigation';

/**
  * Renders a fragment that includes a navigation bar and the main
  * {@link module:Router|router} component.
  * @returns the Container component
  */
export default function Container() {

  return (
    <Router>
      <Navigation />
      <div className="container">
        <div className="row">
          <ApplicationRouter />
        </div>
      </div>
    </Router>
  );
}
 