/**
 * @module Streams
 * @description
 * A router-managed component that shows something.
 */

import React, { useEffect } from 'react';
import { Link, useParams } from "react-router-dom";

import StreamSettings from './StreamSettings';

export default function Stream() {

  let { id } = useParams();

  useEffect(() => {
    if (flvjs.isSupported()) {
      let videoElement = document.getElementById('videoElement');
      let flvPlayer = flvjs.createPlayer({
        type: 'flv',
        url: '/live/' + encodeURIComponent(id) + '.flv'
      });
      flvPlayer.attachMediaElement(videoElement);
      flvPlayer.load();
      flvPlayer.play();
    }
    return function cleanup() {

    };
  });

  return (
    <div>
      <h2 className="ambie-breadcrumb">
        <div className="ambie-breadcrumb-item">
          <Link to="/streams">All streams</Link>
        </div>
        <div className="ambie-breadcrumb-item active" aria-current="page">
          { id }
        </div>
      </h2>

      <video id="videoElement" 
             className="ambiesense-stream ambiesense-video"></video>

      <StreamSettings id={ id }></StreamSettings>
    </div>
  );
}
