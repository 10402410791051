/**
 * @module index
 * @description
 * The main buid point for the react web client component.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import Container from './Container';
import * as serviceWorker from './serviceWorker';
  
const rootElement = document.getElementById("root");

ReactDOM.render(<Container />, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
 