/**
 * @module Router
 * @description
 * The main router component for the web application. This is where the
 * "pages" in the web application are set up.
 */

import React from 'react';
import {
  Switch,
  Route
} from "react-router-dom";

import Home from './Home';
import Streams from './Streams';
import Stream from './Stream';

export default function RootRouter() {
  return (
    <Switch>
      <Route path="/" exact component={ Home } />
      <Route path="/streams" exact component={ Streams } />
      <Route path="/streams/:id" exact component={ Stream } />
    </Switch>
  );
}
